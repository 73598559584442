

import {computed, defineComponent, onMounted, reactive, toRefs} from "vue";
import {useStore} from "vuex";
import {key} from "@/store";
import useTime from "@/composables/useTime";
import topNav from "@/components/home/topNav.vue";
import bottomNav from "@/components/home/bottomNav.vue";
import * as toastr from "toastr";
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ProductDetails',
  components: {
    topNav,
    bottomNav
  },
  setup() {
    const {from_now} = useTime()
    const store = useStore(key)
    const state = reactive({
      showPhone: false
    })
    const { t } = useI18n()

    store.dispatch('product/updateProductView')
    const product = computed(() => store.getters['product/getSingleProduct']);
    const isLoggedIn = computed(() => store.getters['auth/loggedIn'])


    function displayImage(index) {
      $("#rooms-sync1").trigger("to.owl.carousel", index);
    }

    async function updateViewedOwnerPhone(id) {
      if (!isLoggedIn.value) {
        toastr.info(t('productDetailsPage.script.loggedInError'))
      } else {
        state.showPhone = true
        await store.dispatch('product/updateProductViewedOwnerPhone', id)
      }

    }

    onMounted(() => {
      var sync1 = $("#rooms-sync1") as any;
      var sync2 = $("#rooms-sync2") as any;
      sync1.owlCarousel({
        items: 1,
        loop: true,
        margin: 0,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplaySpeed: 400,
        smartSpeed: 400,
        autoplayHoverPause: true,
        slideTransition: 'linear',
      });

      sync2.owlCarousel({
        items: 5,
        margin: 10,
      }) ;

      // Go to the next item
      $('.next-rooms-sync-1').click(function () {
        sync1.trigger('next.owl.carousel');
      })
// Go to the previous item
      $('.prev-rooms-sync-1').click(function () {
        // With optional speed parameter
        // Parameters has to be in square bracket '[]'
        sync1.trigger('prev.owl.carousel', [300]);
      })

    })
    return {
      product,
      displayImage,
      from_now,
      updateViewedOwnerPhone,
      ...toRefs(state)
    }
  },
});

