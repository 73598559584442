/* eslint-disable */
import dayjs from "dayjs";
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import adVancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(adVancedFormat)
dayjs.extend(relativeTime)
import {availableLanguages} from "@/utils/util";

//Detect user's language via web browser preferred language
//const availableLanguages = ["en", "fr"];

const locale =
    [
        ...(window.navigator.languages || []),
        window.navigator.language,
        window.navigator["browserLanguage"],
        window.navigator["userLanguage"],
        window.navigator["systemLanguage"]
    ]
        .filter(Boolean)
        .map(language => language.substr(0, 2))
        .find(language => availableLanguages.includes(language)) || "en";

export default function useTime (){

    function from_now(date){
return dayjs(date).locale(locale).fromNow()
    }

    return {
        from_now,
    }
}